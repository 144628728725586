import { orderBy } from 'lodash';
import {
  horizontalBarDefaultConfig,
  todayAnnotationsDefaultConfig,
  todayVerticalLineDefaultConfig
} from '../../../pages/CDP/const';
import {
  COLOR_SCHEME_TIMELINE,
  CTTIMELINE_CHANGES_GRAPH_SORTING_OPTIONS
} from '../../../pages/ResultsPage/utils/constants';
import { categoryMappingValue, dateFields } from './consts';
import { BAR_CHART_COLOR } from '../../CustomComponents/Graphs/constant';

export const getAnnotations = () => {
  const annotations: Array<any> = [];
  const today = new Date();
  const date = today.toISOString().split('T')[0];
  const annotationText = new Date().toLocaleDateString();
  annotations.push({
    ...todayAnnotationsDefaultConfig,
    x: date,
    text: annotationText
  });
  return annotations;
};

export const getShapes = () => {
  const shapes: Array<any> = [];
  const today = new Date();
  const date = today.toISOString().split('T')[0];
  shapes.push({
    ...todayVerticalLineDefaultConfig,
    line: {
      ...todayVerticalLineDefaultConfig.line,
      color: 'red'
    },
    x0: date,
    x1: date
  });
  return shapes;
};

export const getFilters = (filterData: any[], query: any[]) => {
  const dateRange: any = {};
  if (Object.keys(filterData).length > 0) {
    Object.entries(filterData).forEach(([key, val]) => {
      if (categoryMappingValue[key]) {
        // eslint-disable-next-line no-param-reassign
        key = categoryMappingValue[key];
      }
      if (dateFields[key]) {
        dateRange[key] = {};
        dateRange[key].gte = val[0] || '';
        dateRange[key].lte = val[1] || '';
      } else if (Array.isArray(val)) {
        // eslint-disable-next-line no-param-reassign
        query = [...query, ...val.map((value: any) => ({ category: key, searchText: value }))];
      } else {
        // eslint-disable-next-line no-param-reassign
        query = [...query, { category: key, searchText: val }];
      }
    });
  }
  return { dateRange, query };
};

export const prepareDelayGraphData = (apiData: any, ctTimelineSelectedSort = 'default') => {
  const nctIds: Array<string> = [];
  const barGraphData: Array<any> = [];
  let minDelayDays = 0;
  let maxDelayDays = 0;

  const currentSortingOption = CTTIMELINE_CHANGES_GRAPH_SORTING_OPTIONS.find(
    (ele: any) => ele.id === ctTimelineSelectedSort
  );

  const newData = orderBy(
    apiData,
    [
      (item: any) =>
        item[currentSortingOption.fieldName] ||
        (currentSortingOption.sortType === 'asc' ? undefined : '')
    ],
    [currentSortingOption.sortType as any]
  );

  newData.forEach(
    ({
      acronym,
      nct_id: nctId,
      pcd_delay: pcdDelay,
      overall_status_formated: overallStatusFormated,
      overall_status: overallStatus,
      start_year: startYear,
      start_date: startDate,
      study_first_submitted_date: studyFirstSubmittedDate,
      last_updated_year: lastUpdatedYear,
      study_completion: studyCompletion,
      brief_title: briefTitle,
      primary_completion_date: primaryCompletionDate,
      enrollment: sampleSize,
      official_title: officialTitle,
      phase
    }: any) => {
      let hovertemplate = '';
      if (pcdDelay < 0) {
        hovertemplate = `Acceleration: ${-pcdDelay} | ${nctId}<br>`;
      } else {
        hovertemplate = `Delay: ${pcdDelay} | ${nctId}<br>`;
      }
      hovertemplate +=
        'N: %{customdata.sampleSize} | %{customdata.phase} | %{customdata.acronym}<br>' +
        '<extra></extra>';
      // NCT IDs to render in the Y - Axis
      nctIds.push(nctId);
      // Horizontal Bar Graph data
      // If the NCT ID Present in Review, it should be highlighted with green
      minDelayDays = Math.min(minDelayDays, pcdDelay);
      maxDelayDays = Math.max(maxDelayDays, pcdDelay);
      const barGraphDataObj = {
        ...horizontalBarDefaultConfig,
        name: nctId,
        offsetgroup: nctId,
        legendgroup: overallStatus,
        hovertemplate,
        base: [0],
        x: [pcdDelay],
        y: [nctId],
        text: `N: ${sampleSize} | ${phase}| ${acronym}`,
        textposition: 'inside',
        insidetextanchor: 'start',
        text_auto: '.2s',
        textfont: { color: 'white', size: 14 },
        customdata: [
          {
            sampleSize,
            phase,
            acronym,
            briefTitle,
            officialTitle,
            startYear,
            startDate,
            studyFirstSubmittedDate,
            lastUpdatedYear,
            studyCompletion,
            primaryCompletionDate,
            nctId
          }
        ],
        marker: {
          color: COLOR_SCHEME_TIMELINE[overallStatusFormated]
        },
        nctId
      };
      barGraphData.push({
        ...barGraphDataObj,
        text: pcdDelay,
        textposition: 'outside',
        insidetextanchor: 'start',
        textfont: { color: 'black', size: 12 }
      });
      if (pcdDelay === 0)
        barGraphData.push({
          ...barGraphDataObj,
          alignmentgroup: 'True',
          orientation: 'h',
          type: 'scatter',
          mode: 'markers',
          marker: {
            color: 'black',
            size: 5
          }
        });

      barGraphData.push(barGraphDataObj);
    }
  );
  const minMaxDelayDays = Math.max(Math.abs(minDelayDays - 100), Math.abs(maxDelayDays + 100));
  return {
    nctIds,
    barGraphData,
    minDelayDays: minMaxDelayDays * -1,
    maxDelayDays: minMaxDelayDays
  };
};

export const getCategoryKey = (category: string) => {
  const key = categoryMappingValue[category];

  if (!key) {
    return category;
  }

  return key;
};

export const EUCT_REPORT_QUERIES = [
  {
    id: 'study_completion_year',
    title: {
      title: 'Number of Trials by Completion year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['study_completion_year'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      colors: BAR_CHART_COLOR,
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 30
      },
      tickRotationValue: 0,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#9E9E9E',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'phase',
    title: {
      title: 'Number of Trials by Phase',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['phase'], agg_function: 'count' },
    graphStyle: {
      colors: BAR_CHART_COLOR,
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 100
      },
      tickRotationValue: 0,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#9E9E9E',
      layout: 'horizontal',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'genderGraph',
    title: {
      title: 'Number of Trials by Recruitment Graph',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['gender_graph'], agg_function: 'count' },
    graphStyle: {
      colors: ['#B1BBE4'],
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 120
      },
      tickRotationValue: 90,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#A3A3A3',
      layout: 'horizontal',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  }
];

export const checkAgeIsNull = (value: any, type: string) => {
  if (value && value !== 'NA') {
    return value;
  }
  if (type === 'min') {
    return 0;
  }
  return 99;
};

const createBar = (
  x: number[],
  base: number,
  color: string,
  hoverinfo: string,
  y: any,
  text?: string[]
) => ({
  x,
  y: [y],
  type: 'bar',
  orientation: 'h',
  base,
  marker: { color, opacity: color === '#EEF2F4' ? 0.5 : 1, line: { width: 0 } },
  width: 0.5,
  cliponaxis: false,
  hoverinfo,
  text,
  textposition: 'none'
});

export const prepareAgeRangeGraphData = (dataSet: any[]) => {
  return (
    dataSet?.flatMap((data: any) => {
      const nctId = data?.nct_id;
      const min = checkAgeIsNull(data?.min_age, 'min');
      const max = checkAgeIsNull(data?.max_age, 'max');

      return [
        createBar([99], 0, '#EEF2F4', 'skip', nctId),
        createBar([max - min], min, '#83D0C4', 'text', nctId, [
          `Minimum: ${min} Years, Maximum: ${max} Years, NCT ID: ${nctId}`
        ])
      ];
    }) || []
  );
};
