import React, { useContext, useEffect, useState, useMemo } from 'react';

// mui
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Stack,
  ListItem,
  Chip,
  CircularProgress
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Close } from '@mui/icons-material';
import { DownloadIcon } from '../../../assets/svgs/Icons';
import useQuery from '../../../helpers/customHooks/queryParameter';
import { VISUALIZE_HEADER } from '../constants';
import AddToReportButton from '../Buttons/AddToReportButton';
import BusinessIntelligence from '../../../components/Biologics/BusinessIntelligence/ResultsPageIndex';
import ResultsStore from '../../../store/SearchResults';

import CTTimelineGraph from '../../../components/Timeline/CTGraph/CTTimelineGraph';
import CTAdverseEventsListView from '../../../components/Timeline/CTGraph/CTAdverseEventsListView';
import { analyticViewMapping } from '../../ResultsPage/utils/analyticViewMapping';
import AnalyticView from '../../ResultsPage/components/AnalyticView';
import styles from '../../../components/CustomComponents/BottomDrawer/BottomDrawer.styles';
import EUCTBusinessIntellegence from '../../../components/Timeline/CTGraph/EUCTBusinessIntellegence';
import EUCTAdverseEventsListView from '../../../components/Timeline/CTGraph/EUCTAdverseEventListView';
import IndicationApprovalTimline from './IndicationApprovalTimeline/indicationApprovalTimline';
import handleDownload from '../utils/downloadCSVData';
import USCTGraphs from '../../../components/ClinicalTrials/Graphs/USCTGraphs';
import PatentsExclusivityTimeline from './PatentsExclusivityTimelineView/PatentsExclusivityTimeline';
import CTAgeRangeGraph from '../../../components/Timeline/CTGraph/CTAgeRangeGraph';

const Visualize: React.FC<any> = ({ handleClose, applications, tab = '' }) => {
  const [selectedTab, setSelectedTab] = useState<string>('businessIntelligence');
  const [loading, setLoading] = useState<boolean>(false);
  const { resultsState } = useContext(ResultsStore);
  const [scrollRefElement, setScrollRefElement] = useState<any>(null);

  const query = useQuery();
  const [source, setSource] = useState<string>('us');

  const updateTabAndSource = () => {
    if (tab) {
      setSource(Object.keys(resultsState?.applicationResults)[0]);
      setSelectedTab('matchSubmissions');
    } else if (resultsState?.viewType === 'ct') {
      if (
        Object.values(resultsState.decryptedSource).some(
          valueList => Array.isArray(valueList) && valueList.includes('usnlm')
        )
      ) {
        setSource('ct');
        setSelectedTab('businessIntelligenceCT');
      } else if (
        Object.values(resultsState.decryptedSource).some(
          valueList => Array.isArray(valueList) && valueList.includes('euctr')
        )
      ) {
        setSource('euct');
        setSelectedTab('businessIntelligenceEUCT');
      }
    } else if (applications.some((app: { source: string }) => app.source === 'us')) {
      setSource('us');
    } else if (applications.some((app: { source: string }) => app.source === 'eu')) {
      setSource('eu');
      setSelectedTab('analyticsView');
    } else if (applications.some((app: { source: string }) => app.source === 'ca')) {
      setSource('ca');
      setSelectedTab('analyticsView');
    } else {
      setSource('default');
      setSelectedTab('analyticsView');
    }
  };

  useEffect(() => {
    updateTabAndSource();
  }, [query, resultsState.viewType]);

  const handleChange = (key: string) => {
    setSelectedTab(key);
  };

  const getSource = useMemo(() => {
    if (selectedTab === 'businessIntelligence') return ['us'];
    if (source === 'euct') return ['euctr'];
    if (source === 'ct') return ['ct'];
    if (Object.keys(resultsState.applicationResults).length !== 0) {
      return Object.keys(resultsState.applicationResults).map(item =>
        item === 'ie' ? 'hpra' : item
      );
    }
    return '';
  }, [resultsState.applicationResults, selectedTab]);

  const getSearchTerm = useMemo(() => {
    return resultsState.decryptedPayload?.query || '';
  }, [resultsState.decryptedPayload]);

  const filterApplicationBySource = () => {
    if (source === 'us' && resultsState.applicationResults.us) {
      return resultsState.applicationResults[source]?.results ?? [];
    }
    return [];
  };

  const downloadImage = async () => {
    const fileName = resultsState?.decryptedPayload?.searchType
      ? 'advanced-search-indication-sequencing'
      : `indication-sequencing-${resultsState?.decryptedPayload?.query}`;

    const element = scrollRefElement?.current;
    if (!element) return;

    setLoading(true);
    try {
      const { default: html2canvas } = await import('html2canvas');
      const canvas = await html2canvas(element, {
        scale: 1,
        scrollY: 0,
        scrollX: 0,
        width: element.scrollWidth,
        height: element.scrollHeight
      });
      const link = document.createElement('a');
      link.download = fileName;
      link.href = canvas.toDataURL();
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'businessIntelligence':
        return <BusinessIntelligence payload={filterApplicationBySource()} />;
      case 'businessIntelligenceCT':
        return <USCTGraphs payload={resultsState?.ctBussinessIntelligence} />;
      case 'timelineGantt':
      case 'timelineGanttEUCT':
        return <CTTimelineGraph type='gantt' />;
      case 'timelineChanges':
      case 'timelineChangesEUCT':
        return <CTTimelineGraph type='changes' />;
      case 'adverseEffect':
        return <CTAdverseEventsListView />;
      case 'analyticsView':
        return <AnalyticView applicationData={analyticViewMapping(applications)} />;
      case 'businessIntelligenceEUCT':
        return <EUCTBusinessIntellegence />;
      case 'adverseEffectEUCT':
        return <EUCTAdverseEventsListView />;
      case 'matchSubmissions':
        return <IndicationApprovalTimline setScrollRefElement={setScrollRefElement} />;
      case 'patentsExclusivity':
        return <PatentsExclusivityTimeline setScrollRefElement={setScrollRefElement} />;
      case 'ageRangeChart':
        return <CTAgeRangeGraph />;
      default:
        return null;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.titleWrapper}>
          <Typography sx={styles.title}>Visualize</Typography>
          <Box sx={styles.actionsWrapper}>
            <Tooltip title='Close'>
              <IconButton size='small' sx={styles.closeButton} onClick={handleClose}>
                <Close fontSize='small' />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Stack direction='row' justifyContent='space-between' sx={styles.headerContainer}>
        <Stack direction='row'>
          {VISUALIZE_HEADER[source]?.map((type: any) => (
            <ListItem key={type.id} sx={{ pl: 0, pr: '8px', py: 0 }}>
              <Chip
                onClick={() => handleChange(type.id)}
                sx={styles.chip}
                variant={selectedTab === type.id ? 'filled' : 'outlined'}
                label={type.label}
              />
            </ListItem>
          ))}
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
          <AddToReportButton
            source={getSource}
            search={getSearchTerm}
            module='core'
            searchType={resultsState.decryptedPayload?.searchType ?? 'quicksearch'}
            sourceType='SEARCH_ID'
            disableSelectionSource={false}
          />
          {selectedTab === 'matchSubmissions' && (
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
              <Divider orientation='vertical' sx={styles.verticalDivider} />
              <DownloadIcon
                onClick={() =>
                  handleDownload(applications, resultsState?.decryptedPayload?.query ?? '')
                }
                sx={styles.buttonIcon}
              />
              <Tooltip title={loading ? 'Downloading...' : 'Save as png'}>
                {loading ? (
                  <CircularProgress color='success' size={14} />
                ) : (
                  <CameraAltIcon onClick={downloadImage} sx={styles.downloadbuttonIcon} />
                )}
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          ...styles.childrenWrapper,
          height: 'calc(98% - 96px)'
        }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default React.memo(Visualize);
