export const categoryMappingValue: { [key: string]: any } = {
  name: 'sponsor_name',
  interventions: 'active_ingredients',
  indications_and_usage: 'indications_and_usage',
  primary_endpoint: 'primary_endpoint'
};

export const dateFields: { [key: string]: any } = {
  primary_completion_date: 'primary_completion_date',
  completion_date: 'completion_date',
  last_update_submitted_date: 'last_update_submitted_date',
  study_first_submitted_date: 'study_first_submitted_date'
};

export const AGE_RANGE_GRAPH_LAYOUT = {
  title: '',
  xaxis: { title: 'Years', fixedrange: true, anchor: 'x', range: [0, 99] },
  yaxis: { title: 'Trials', type: 'category', autorange: true, anchor: 'y' },
  barmode: 'overlay',
  autosize: true,
  colorway: ['#bdbdbd', '#777777'],
  grid: {
    rows: 1,
    columns: 1,
    roworder: 'top to bottom',
    subplots: ['xy'],
    yaxes: ['y']
  },
  margin: {
    t: 50,
    b: 50
  },
  showlegend: false,
  uirevision: 'true',
  template: {
    layout: {
      xaxis: {
        ticks: '',
        title: {
          standoff: 15
        },
        automargin: true,
        zerolinewidth: 2
      },
      yaxis: {
        ticks: '',
        title: {
          standoff: 15
        },
        automargin: true,
        zerolinewidth: 2
      },
      hovermode: 'closest',
      hoverlabel: {
        align: 'left'
      }
    }
  }
};
