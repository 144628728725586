import React, { lazy, useCallback, useContext, useState, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Alert, Box, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import ReportButton from '../../Report/ReportButton';
import ResultsStore from '../../../store/SearchResults';
import { AGE_RANGE_GRAPH_LAYOUT } from './consts';
import useReportingHandler from '../../Report/hooks/useReportingHandler';
import getSearchPathParams from '../../Header/utils/getSearchPathParams';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import { handleCTGraphFetch } from '../../../pages/ResultsPage/hoc/withFetchingSearchResults';
import { sourceMap } from '../../../pages/ResultsPage/components/SearchSuggestions';
import { prepareAgeRangeGraphData } from './utils';

const TimeSeriesGraph = lazy(() => import('../../CustomComponents/Graphs/CustomTimeSeries'));

const CTAgeRangeGraph = () => {
  const location = useLocation();
  const { resultsState } = useContext(ResultsStore);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const { updateReportData } = useReportingHandler();
  const graphRef = useRef();

  const handleOnAlertClose = useCallback(() => setWarningMessage(''), []);

  const addRef = useCallback((ref: any) => {
    graphRef.current = ref;
  }, []);

  const trialsCount = useMemo(() => {
    return resultsState?.trialsData?.trials_data?.length;
  }, [resultsState?.trialsData?.trials_data]);

  const rangeTraces = useMemo(() => {
    if (trialsCount > 50) {
      setWarningMessage(
        'For optimal chart experience, please narrow results to less than 30 trials'
      );
    }
    return prepareAgeRangeGraphData(resultsState?.trialsData?.trials_data);
  }, [resultsState?.trialsData.trials_data]);

  const layout = useMemo(() => {
    return cloneDeep(AGE_RANGE_GRAPH_LAYOUT);
  }, []);

  const handleAddToReport = async (id: any, reportData: any) => {
    const { searchId }: any = getSearchPathParams(location.pathname);
    const source = 'ct';
    const {
      search_term: searchTerm,
      entity_text: entityText,
      entity_category: entityCategory,
      search_type: searchType
    }: any = decodeBase64ToObject(searchId ?? '');
    const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
      searchType,
      resultsState.decryptedPayload,
      resultsState.filters,
      entityCategory || 'indications_and_usage',
      entityText || searchTerm
    );
    let referenceText = '';

    if (source in sourceMap) {
      referenceText += sourceMap[source];
    }
    if (searchTerm || entityText) {
      referenceText += ` / ${searchTerm || entityText}`;
    }
    const reportStateValue = {
      templateType: 'custom',
      chartType: 'CT_AGE_RANGE_GANTT',
      sectionType: 'CHART',
      id: reportData?.reportId,
      style: {
        placement: {
          h: 30,
          w: 12,
          i: reportData?.reportId,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        },
        references: {
          show: true,
          href: window.location.href,
          text: `${referenceText}`
        },
        title: {},
        graphStyle: {}
      },
      data: {
        source,
        resultId: resultsState?.searchIds?.ctSearchId,
        filters: {
          queryCNF: cnfOfSelectedQuery,
          fda: resultsState?.filters,
          dateFilters: dateRange,
          defaultFilters: false,
          trialsSource: source,
          useSynonyms
        }
      }
    };

    updateReportData(reportData?.id, reportStateValue);
  };

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Clinical Trials Age Range Chart</Typography>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box display='inline-block' pr={1}>
            <ReportButton
              data='Clinical Trials Age Range'
              addToReport={handleAddToReport}
              prefixId='ctAgeRange'
            />
          </Box>
        </Stack>
      </Stack>
      <Box display='flex'>
        <Box alignSelf='end'>
          {resultsState.trialLoading || resultsState.loading ? (
            <Skeleton
              variant='rectangular'
              height={16}
              width={120}
              sx={{ mr: 1, borderRadius: 1 }}
            />
          ) : (
            <Typography
              sx={{
                fontSize: 14,
                color: 'gray.700',
                fontWeight: 400,
                fontFamily: 'Mulish'
              }}>
              Total {trialsCount} trials
            </Typography>
          )}
        </Box>
      </Box>
      <Box width='90vw' display='flex' height='70vh' pb={2} overflow='hidden' position='relative'>
        <Box width='100%' display='flex' flexDirection='column' height='100%' overflow='hidden'>
          <TimeSeriesGraph
            ref={addRef}
            barGraphData={rangeTraces}
            plotConfig={{
              toImageButtonOptions: {
                filename: `clinical_trials_age_range_plot`
              }
            }}
            customLayout={layout}
          />
        </Box>
      </Box>
      <Snackbar
        open={!!warningMessage}
        autoHideDuration={6000}
        onClose={handleOnAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Alert onClose={handleOnAlertClose} severity='warning' sx={{ width: '100%' }}>
          <Typography>{warningMessage}</Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default React.memo(CTAgeRangeGraph);
